import { useQuery } from "@tanstack/react-query";
import { sendAxiosGetRequest, sendAxiosPostRequest } from "components/utils/methods/myRequestHandlers";
import { useUserStore } from "components/utils/stores/userStore";

export default function useGetDbMessages(props) {
    const userStore = useUserStore();

    return useQuery({
        queryKey: ["getDbMessages"],
        queryFn: () => sendAxiosPostRequest({
            route: `v5/select/business/messages/reviewed`,
            // route: `v3/business/select/businesses/${userStore.companyData.id}/dashboards/messages`,
            body: {
                BusinessId: userStore.companyData.id,
                FilterPhaseId: props.filterPhaseId || null,
                FilterUserId: props.filterUserId || null,
            },
            callback: (res) => {
                if (res.data.status === 290) {
                    return [];
                } else {
                    return res.data.instance.sort((a, b) => a.updatedOn > b.updatedOn ? -1 : 1)
                }
            }
        }),
        enabled: props.enabled || false,
        refetchOnWindowFocus: false,
        retry: false
    })
}