import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Loading from "components/animations/loading";
import AccordionCommunication from "components/layouts/accordions/accordion-communication";
import NoData from "components/layouts/no-data/nodata";
import { ratingAccordionIcon } from "components/utils/icons/icons";
import { handleRequestError, handleRequestSuccess, hasPermission } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";
import { Fragment, useEffect, useState } from "react";
import TipsMessage from "./TipsMessage";
import { sendAxiosPostRequest } from "components/utils/methods/myRequestHandlers";

export default function TipsAccordion(props) {
    // # STORES
    const userStore = useUserStore();

    // # CLIENT STATES
    const [notRepliedCounter, setNotRepliedCounter] = useState(null); // COUNT NOT REPLIED
    const [allTipsMessages, setTipsMessages] = useState(null); // GET ALL TIPS

    // # SERVER STATES
    const { refetch: tipsMessagesRefetch, isLoading: tipsIsLoading, isRefetching, isError } = useGetTipsMessages({ setData: setTipsMessages })

    useEffect(() => {
        if (!allTipsMessages) { return }
        var total = 0;
        allTipsMessages.forEach(element => {
            if (element.replyText === null) {
                if (element.isNoted) { return }
                if (Boolean(element.isAnonymous) === false) { total++ }
            }
        });
        if (total > 9) {
            total = "9+";
        }

        setNotRepliedCounter(total);
    }, [allTipsMessages])

    return <>
        <AccordionCommunication
            icon={ratingAccordionIcon}
            title={<>{t("business_hero_panel_feedback_title")}
                <span className="pl-2">
                    {notRepliedCounter === 0 || notRepliedCounter === null ? null :
                        <span className="counter-circle">{notRepliedCounter}</span>}
                </span></>}
        >
            <div className="content-wrapper" id="home-feedback">
                {
                    tipsIsLoading || isRefetching ? <div className="mb-4"><Loading text={null} center /></div> :
                        isError ? <NoData error className="mb-5" /> :
                            !userStore.userData.permissionsData ? null :
                                !hasPermission(userStore.userData.permissionsData, "PM_VIEW_FEEDBACK") ? <div className="pb-5">
                                    <NoData wise="cross" text={t("business_extra_permission_required")} />
                                </div> :
                                    allTipsMessages === null ? <div className="py-5 pt-2">
                                        <NoData wise="notfound" text={t("business_hero_feedback_empty")} />
                                    </div> :
                                        <>
                                            {
                                                !allTipsMessages ? <div className="d-flex justify-content-center"><Loading text={null} big /></div> :
                                                    <div className="feedback-items container py-3  messages-flow" style={{ backgroundColor: "rgba(232, 232, 232, 0.4)" }} >
                                                        {
                                                            allTipsMessages.map((tip, i) => <Fragment key={i}>
                                                                <TipsMessage
                                                                    data={tip}
                                                                    getData={tipsMessagesRefetch}
                                                                />
                                                            </Fragment>)
                                                        }
                                                    </div>
                                            }
                                        </>
                }
            </div>
        </AccordionCommunication>
    </>
}


export const useGetTipsMessages = (props) => {
    const userStore = useUserStore();
    return useQuery({
        queryKey: ["getHintMessages"],
        queryFn: () => sendAxiosPostRequest({
            route: `v5/select/company/feedback`,
            body: {
                CompanyId: userStore.companyData.id,
                UserId: userStore.userData.id
            },
            callback: (res) => {
                // // ORDER MESSAGES USING CREATION DATE
                // var newmsgs = res.data.instance.sort(function (a, b) {
                //     if (a.createdOn < b.createdOn) { return 1; }
                //     if (a.createdOn > b.createdOn) { return -1; }
                // });

                // // ORDER UNANSWERED MESSAGES ON TOP
                // newmsgs = newmsgs.sort(function (a, b) {
                //     if (a.answer === null) {
                //         return -1;
                //     } else {
                //         return 1;
                //     }
                // });

                // // ORDER GUEST TIPS TO BOTTOM
                // newmsgs = newmsgs.sort(function (a, b) {
                //     if (b.isAnonymous && b.isNoted) { return -1 }
                // });

                // // ORDER UNANSWERED MESSAGES WITH NEW ONES ON TOP
                // newmsgs = newmsgs.sort(function (a, b) {
                //     if (a.replyText === null) {
                //         // ORDER IS NOTED AT BOTTOM OF UNREAD MESSAGES
                //         if (a.isNoted) {
                //             if ((parseInt(a.isNoted)) < (parseInt(b.isNoted))) { return 1; }
                //             if ((parseInt(a.isNoted)) > (parseInt(b.isNoted))) { return -1; }
                //         } else {
                //             if (a.createdOn < b.createdOn) { return 1; }
                //             if (a.createdOn > b.createdOn) { return -1; }
                //         }
                //     } else {
                //         return 1;
                //     }
                // });

                // // // ORDER ANSWERED BY DATE
                // // newmsgs = newmsgs.sort(function (a, b) {
                // //     if (a.replyText) {
                // //         if (a.replyCreatedOn < b.replyCreatedOn) { return 1; }
                // //         if (a.replyCreatedOn > b.replyCreatedOn) { return -1; }

                // //     } else {
                // //         return 0;
                // //     }
                // // });

                // ORDER NOTED MESSAGES AT BOTTOM WITH NEW ONES ON TOP
                var newmsgs = res.data.instance.sort((a, b) => {
                    // ORDER IS NOTED AT BOTTOM OF UNREAD MESSAGES
                    if (a.isNoted && b.isNoted) {
                        return (parseInt(b.isNoted)) - (parseInt(a.isNoted));
                    } else if (!a.isNoted && !b.isNoted) {
                        return (new Date(b.createdOn)) - (new Date(a.createdOn));
                    } else {
                        if (a.isNoted) { return 1; }
                        if (b.isNoted) { return -1; }
                    }
                    return 0;
                });

                props.setData(newmsgs);

                return newmsgs
            }
        }),
        retry: 2,
        refetchOnWindowFocus: false,
        onError: (res) => handleRequestError({ response: res }),
    })
}