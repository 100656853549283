import { Empty, Modal, Select, Table } from "antd";
import AccordionContent from "components/layouts/accordions/accordionContent";
import DashboardLayout from "components/layouts/dashboard/dashboard-layout";
import CandidateBrandExperiencePanel from "components/layouts/dashboard/panels/candidate-brand-experience-panel";
import MessagesPanel from "components/layouts/dashboard/panels/messages-panel";
import ScoreEvolutionPanel from "components/layouts/dashboard/panels/score-evolution-panel";
import { useGetDbGeneralData } from "components/requests/dashboard/getDbGeneralData";
import useGetDbMessages from "components/requests/dashboard/getDbMessages";
import { useGetDbScoreEvolution } from "components/requests/dashboard/getDbScoreEvolution";
import { useUserStore } from "components/utils/stores/userStore";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { adminRoleId } from "components/utils/methods/AppMethods";
import { sendAxiosGetRequest, sendAxiosPostRequest } from "components/utils/methods/myRequestHandlers";
import { useQuery } from "@tanstack/react-query";
import Rating from "components/modules/rating/rating";
import { Link } from "react-router-dom";

export default function AccelerateDashboardPage(props) {
    // # STORES
    const userStore = useUserStore();
    const { t } = useTranslation()
    const [filterUserId, setFilterUserId] = useState(null);
    const [filterPhaseId, setFilterPhaseId] = useState(null);
    const [showCeKeysModal, setShowCeKeysModal] = useState(false);
    const isAdmin = userStore.userData && userStore.userData.roleData && userStore.userData.roleData.id.toLowerCase() === adminRoleId.toLowerCase() ? true : false;

    // # SERVER STATES
    // base data
    const { data: dbGeneralData, isLoading: dbGeneralDataIsLoading, isRefetching: dbGeneralDataIsRefetching, isError: dbGeneralDataIsError, refetch } = useGetDbGeneralData({ enabled: true }); // company data (number of ratings, rating, companyname, planname)

    const { data: dbScoreEvolution, isLoading: dbScoreEvolutionIsLoading, isError: dbScoreEvolutionIsError, refetch: dbScoreEvolutionRefetch, isRefetching: dbScoreEvolutionIsRefetching } = useGetDbScoreEvolution({ category: "General", key: "getScore-BC_General", enabled: true });
    const { data: dbScoreEvolutionCandidate, isLoading: dbScoreEvolutionCandidateIsLoading, isError: dbScoreEvolutionCandidateIsError, refetch: dbScoreEvolutionCandidateRefetch, isRefetching: dbScoreEvolutionCandidateIsRefetching } = useGetDbScoreEvolution({ category: "Experience", key: "getScore-BC_CandidateExperience", enabled: true });
    const { data: dbScoreEvolutionBrand, isLoading: dbScoreEvolutionBrandIsLoading, isError: dbScoreEvolutionBrandIsError, refetch: dbScoreEvolutionBrandRefetch, isRefetching: dbScoreEvolutionBrandIsRefetching } = useGetDbScoreEvolution({ category: "Appearance", key: "getScore-BC_EmployerBrand", enabled: true });

    const { data: dbMessages, isLoading: dbMessagesIsLoading, isError: dbMessagesIsError, isRefetching: dbMessagesIsRefetching, refetch: dbMessagesRefetch } = useGetDbMessages({ filterPhaseId, filterUserId, enabled: true });



    const { data: recruitersData, isLoading: recruitersDataIsLoading, isSuccess: recruitersDataIsSuccess } = useQuery({
        cacheTime: 0,
        queryKey: ["dbGetAllRecruiters"],
        queryFn: () => sendAxiosPostRequest({
            route: `v5/select/business/recruiters`,
            body: {
                BusinessId: userStore.companyData.id
            },
            callback: (res) => {
                if (res && res.data && res.data.status === 290) { return [] }
                if (res && res.data && res.data.instance && res.data.instance.length > 0) {
                    var newArray = []
                    res.data.instance.map((item) => {
                        if (isAdmin) {
                            newArray.push({
                                label: `${item.firstName}${item.lastName ? ` ${item.lastName}` : ""}`,
                                value: item.id,
                                raw: item
                            })
                        } else {
                            if (item.id === userStore.userData.id) {
                                setFilterUserId(item.id);
                                newArray.push({
                                    label: `${item.firstName}${item.lastName ? ` ${item.lastName}` : ""}`,
                                    value: item.id,
                                    raw: item
                                })
                            }
                        }
                    })

                    return newArray;
                }
                else {
                    return []
                }
            }
        })
    })


    const { data: phasesData, isLoading: phasesDataIsLoading } = useQuery({
        queryKey: ["dbGetAllPhases"],
        queryFn: () => sendAxiosGetRequest({
            route: `v2/generic/catalogues/JobPhases`,
            callback: (res) => {
                if (res && res.data && res.data.instance && res.data.instance.length > 0) {
                    return res.data.instance.map((item) => {
                        return {
                            label: t(item.translationKey),
                            value: item.id,
                            raw: item
                        }
                    })
                }
            }
        })
    })
    const { data: ceKeysData, isLoading: ceKeysDataIsLoading } = useQuery({
        queryKey: ["dbGetAllCEKeys"],
        queryFn: () => sendAxiosPostRequest({
            route: `v5/select/business/agencies`,
            body: {
                BusinessId: userStore.companyData.id
            },
        }),
        enabled: !!(userStore.companyData && userStore.companyData.id),
    })

    return <>
        <DashboardLayout data={dbGeneralData} type={"Accelerate"}>
            <>
                <Modal
                    open={showCeKeysModal}
                    onCancel={() => setShowCeKeysModal(false)}
                    title={t("business_dashboard_ce_keys_modal_title")}
                    footer={null}
                >
                    <>
                        <div className="flex flex-column align-items-start" style={{ gap: 5 }}>
                            {
                                ceKeysData && ceKeysData.length > 0 && ceKeysData.map((item, index) => {
                                    return <Link key={index} to={`/ce-dashboard?id=${item.id}`} className="link mb-0">{item.name}</Link>
                                })
                            }

                        </div>
                    </>
                </Modal>
                <div className="row">
                    <div className="flex flex-row align-items-start justify-content-between mb-4">

                        {
                            dbGeneralData && <div className="flex flex-column align-items-start" style={{ gap: 10 }}>
                                <div className="flex flex-row align-items-center" style={{ gap: 10 }}>
                                    <h2 className="mb-0 fw-bold">{dbGeneralData && dbGeneralData.totalCount ? dbGeneralData.totalCount : "-"} {t("business_dashboard_total_score")}</h2>
                                    <Rating rating={!dbGeneralData ? 0 : !dbGeneralData.scoreGeneral ? null : dbGeneralData.scoreGeneral} noamount />
                                </div>
                                {
                                    isAdmin && ceKeysData && ceKeysData.length > 0 && <p
                                        className="link mb-0 inline-block"
                                        onClick={() => setShowCeKeysModal(true)}>
                                        {t("business_dashboard_show_ce_keys_modal")}
                                    </p>
                                }

                            </div>
                        }
                        <div className="flex flex-row align-items-center flex-nowrap" style={{ gap: 5 }}>
                            {
                                recruitersData && isAdmin &&
                                <Select
                                    className="w-100"
                                    defaultValue={null}
                                    style={{ minWidth: 300 }}
                                    loading={recruitersDataIsLoading}
                                    onChange={(value) => {
                                        if (value === null) setFilterUserId(null);
                                        else setFilterUserId(value);
                                    }}
                                    options={recruitersData ? [{ label: t("business_dashboard_filter_select_all_recruiters"), value: null }, ...recruitersData] : []}
                                />
                            }
                            {
                                phasesData &&
                                <Select
                                    className="w-100"
                                    defaultValue={null}
                                    style={{ minWidth: 300 }}
                                    loading={phasesDataIsLoading}
                                    onChange={(value) => {
                                        if (value === null) setFilterPhaseId(null);
                                        else setFilterPhaseId(value);
                                    }}
                                    options={phasesData ? [{ label: t("business_dashboard_filter_select_all_phases"), value: null }, ...phasesData] : []}
                                />
                            }
                        </div>
                    </div>
                </div>
                <section className="row mb-10">
                    <DemoPanelDedicatedApplicants />
                    <div className="col gx-2">
                        <ScoreEvolutionPanel
                            data={[
                                {
                                    id: t("business_dashboard_average_score"),
                                    data: dbScoreEvolution
                                },
                                {
                                    id: t("business_dashboard_average_score_candidate"),
                                    data: dbScoreEvolutionCandidate
                                },
                                {
                                    id: t("business_dashboard_average_score_brand"),
                                    data: dbScoreEvolutionBrand
                                },
                            ]}
                            panelProps={{
                                isLoading: (dbScoreEvolutionCandidateIsLoading || dbScoreEvolutionBrandIsLoading),
                                isError: (dbScoreEvolutionCandidateIsError || dbScoreEvolutionBrandIsError),
                                isRefetching: (dbScoreEvolutionCandidateIsRefetching || dbScoreEvolutionBrandIsRefetching),
                            }}
                        />
                    </div>
                </section>
                {/* ### APPLICANT EXPERIENCE ###  */}
                <section>
                    <h1 className="title mb-4">{t("business_dashboard_section_experience_title")}</h1>
                    <>
                        <div className="row">
                            <div className="col gx-2">
                                <CandidateBrandExperiencePanel
                                    filterPhaseId={filterPhaseId}
                                    filterUserId={filterUserId}
                                    catFilter={(item) => item === 10}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col gx-2">
                                <MessagesPanel
                                    data={dbMessages}
                                    panelProps={{
                                        isLoading: dbMessagesIsLoading || dbMessagesIsRefetching, isError: dbMessagesIsError,
                                        isRefetching: dbMessagesIsRefetching,
                                    }}
                                />
                            </div>
                        </div>
                    </>

                </section>
            </>
        </DashboardLayout>
    </>
}

const DemoPanelDedicatedApplicants = () => {

    const data = {
        items: [
            {
                "jobName": "Sales support & administration",
                "date": "Jul '24",
                "participants": "16",
                "engagement": "55%",
                "list": "",
            },
            {
                "jobName": "",
                "date": "",
                "participants": "",
                "engagement": "",
                "list": "Koen Janssens",
            },
            {
                "jobName": "",
                "date": "",
                "participants": "",
                "engagement": "",
                "list": "An Van Esp",
            },
            {
                "jobName": "",
                "date": "",
                "participants": "",
                "engagement": "",
                "list": "Kathleen De Kort",
            },
            {
                "jobName": "",
                "date": "",
                "participants": "",
                "engagement": "",
                "list": "Liesbeth Vincken",
            },
            {
                "jobName": "",
                "date": "",
                "participants": "",
                "engagement": "",
                "list": "Filip De Keersmaker",
            },
            {
                "jobName": "",
                "date": "",
                "participants": "",
                "engagement": "",
                "list": "Arne Casteels",
            },
            {
                "jobName": "",
                "date": "",
                "participants": "",
                "engagement": "",
                "list": "Paul Verwees",
            },
            {
                "jobName": "",
                "date": "",
                "participants": "",
                "engagement": "",
                "list": "Lea Papen",
            },
            {
                "jobName": "Business Analyst",
                "date": "Jun '24",
                "participants": "4",
                "engagement": "75%",
                "list": "",
            },
            {
                "jobName": "",
                "date": "",
                "participants": "",
                "engagement": "",
                "list": "Peter Stel",
            },
            {
                "jobName": "",
                "date": "",
                "participants": "",
                "engagement": "",
                "list": "Ilse Meesters",
            },
            {
                "jobName": "",
                "date": "",
                "participants": "",
                "engagement": "",
                "list": "Marie Fel",
            },
        ]
    }

    const columns = [
        {
            title: "Vacancy",
            dataIndex: 'jobName',
            key: 'jobName',
        },
        {
            title: "Date",
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: "N° participants",
            dataIndex: 'participants',
            key: 'participans',
        },
        {
            title: "% of engagement",
            dataIndex: 'engagement',
            key: 'engagement',
        },
        {
            title: "List",
            dataIndex: 'list',
            key: 'list',
        },
    ];

    return <> <div className={`${'row mx-auto mb-4'}`}>
        <div className={`${'col gx-2 mx-auto'}`}>
            <AccordionContent title="Overview: dedicated applicants">
                <>
                    <Table size="middle" locale={{
                        emptyText: <>
                            <Empty description={t("business_extra_no_data")} />
                        </>
                    }} className="m-0" pagination={false} columns={columns} dataSource={data.items} />

                </>

            </AccordionContent>
        </div>
    </div>
    </>
}