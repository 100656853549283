//Stylesheets & layouts
import Footer from "./components/layouts/footer/footer";
import Header from "./components/layouts/header/header";
import Navigation from "./components/layouts/navigation/navigation";
import ScrollToTop from "./components/layouts/navigation/scrollToTop";
import "./sass/styles.css";

//Pages
import CompanyInfoPage from "./pages/company/company-info";
import ForgotPage from "./pages/forgot";
import HomePage from "./pages/home";
import LoginPage from "./pages/login";
import Template from "./pages/template";
import WisePage from "./pages/wise";

//Modules
import { useQuery } from "@tanstack/react-query";
import axios from 'axios';
import GuestRedirectLayout from "components/layouts/page-layouts/guest-redirect";
import useGetPlans from "components/requests/data/useGetPlans";
import { handleUserLoginData } from "components/utils/methods/AppMethods";
import { handleRequestError, handleRequestSuccess } from "components/utils/methods/MyMethods";
import { useLanguagesStore } from "components/utils/stores/languagesStore";
import { usePlansStore } from "components/utils/stores/plansStore";
import { useUserStore } from "components/utils/stores/userStore";
import i18n from 'i18next';
import CreateVisualPage from "pages/create-visual";
import NewDashboardPage from "pages/dashboards/dashboard";
import { useEffect } from 'react';
import Helmet from 'react-helmet';
import { initReactI18next, useTranslation } from 'react-i18next';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Cookies from 'universal-cookie';
import localizationDev from "./assets/localizations/dev.json";
import localizationEnglish from "./assets/localizations/en.json";
import localizationFrench from "./assets/localizations/fr.json";
import localizationDutch from "./assets/localizations/nl.json";
import localizationSeo from "./assets/localizations/seo.json";
import Loading from "./components/animations/loading";
import CookiesPopup from "./components/layouts/cookies/cookies-popup.jsx";
import envir from './env.json';
import AcceptPage from "./pages/accept";
import AccountAlertsPage from "./pages/account/account-alerts";
import AccountGeneralPage from "./pages/account/account-general";
import AccountGeneralRecruiterPage from "./pages/account/account-general-recruiter";
import AccountSecurityPage from "./pages/account/account-security";
import CompanyPlansPage from "./pages/company/company-plans";
import CompanyProcedurePage from "./pages/company/company-procedure";
import CompanyPromisePage from "./pages/company/company-promise";
import CompanyRecruitmentPage from "./pages/company/company-recruitment";
import CompanyRecruitmentHistoryPage from "./pages/company/company-recruitmenthistory";
import CompanySharePage from "./pages/company/company-share";
import CompanyTeamPage from "./pages/company/company-team";
import CompanyUpgradePage from "./pages/company/company-upgrade";
import InvitePage from "./pages/invite";
import JobsPage from "./pages/jobs/jobs";
import LogoutPage from "./pages/logout";
import PolicyPage from "./pages/policy";
import RecoverAccountPage from "./pages/recover";
import RecruiterBrandingInfoPage from "./pages/recruiter-branding/recruiter-branding-info";
import RecruiterBrandingRecommendationsPage from "./pages/recruiter-branding/recruiter-branding-recommendations";
import RegisterPage from "./pages/register/register";
import TermsPage from "./pages/terms";
import VerificationPage from "./pages/verification";
import HomeRedirectPage from "pages/home-redirect";
import RecruitmentDashboardPage from "pages/dashboards/recruitment-dashboard";
import CandidateInsightDashboardPage from "pages/dashboards/candidate-insight-dashboard";
import MustHaveDashboardPage from "pages/dashboards/must-have-dashboard";
import DashboardAgency from "pages/dashboards/agency-db";
import CeDashboardPage from "pages/dashboards/ce-dashboard";
import AccelerateDashboardPage from "pages/dashboards/accelerate-dashboard";
import ImproveDashboardPage from "pages/dashboards/improve-dashboard";
import DeepDiveDashboardPage from "pages/dashboards/deep-dive-dashboard";

// DISABLE CONSOLE LOGGING
if (!envir.Variables.DevConsole || envir.Variables.DevConsole === undefined || envir.Variables.DevConsole === null) {
  console.log = function () { }
}

//Init translation listener
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: localizationEnglish },
    nl: { translation: localizationDutch },
    fr: { translation: localizationFrench },
    dev: { translation: localizationDev },
    seo: { translation: localizationSeo }
  },
  lng: "nl",
  fallbackLng: ["seo", "en", "nl", "fr", "dev"]
});

function App() {

  // # UTILS
  const { t } = useTranslation();
  const cookies = new Cookies();

  // # STORES
  const plansStore = usePlansStore();
  const userStore = useUserStore();
  const languagesStore = useLanguagesStore();

  // # SERVER STATES
  const { refetch: plansRefetch } = useGetPlans();
  const { refetch: rememberMeRefetch } = useRememberMe({ token: localStorage.getItem("cToken") });

  // set axios defaults
  axios.defaults.baseURL = envir.Variables.WiseApiUrl;
  axios.defaults.headers.common['x-functions-key'] = envir.Variables.WiseApiKey;
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.timeout = 10000;


  // REMEMBER ME
  useEffect(() => {
    if (userStore.clientData.checkedRememberMe) { return }
    if (localStorage.getItem("cToken") === null || localStorage.getItem("cToken") === undefined) {
      return userStore.changeClientDataItem("checkedRememberMe", true)
    }
    rememberMeRefetch();
  }, []);

  useEffect(() => {
    if (userStore.clientData.acceptedCookies) { return }

    if (localStorage.getItem("cookies") !== null) {
      if (parseInt(localStorage.getItem("cookies")) === 0) {
        userStore.changeClientDataItem("acceptedCookies", false);
        window['ga-disable-G-7NP9JRE9XD'] = true;
        window['ga-disable-UA-27304107-9'] = true;

        cookies.remove('_ga', { path: '/' });
        cookies.remove('_ga_7NP9JRE9XD', { path: '/' });
        cookies.remove('_ga_N4KYL1JKBY', { path: '/' });
        cookies.remove('_gat_UA-27304107-9', { path: '/' });
      }
      if (parseInt(localStorage.getItem("cookies")) === 1) {
        userStore.changeClientDataItem("acceptedCookies", true);
        return;
      }
    }
  }, [userStore.clientData.acceptedCookies]);

  // handle language on init load 
  useEffect(() => {
    if (!languagesStore.data) { return }
    if (localStorage.getItem("lang") === undefined || localStorage.getItem("lang") === null) {
      localStorage.setItem("lang", userStore.clientData.language.isoCode);
    } else {
      if (localStorage.getItem("lang").toLowerCase() !== userStore.clientData.language.isoCode.toLowerCase()) {
        i18n.changeLanguage(localStorage.getItem("lang").toLowerCase());
        const selectedLang = languagesStore.data.filter(lang => lang.isoCode.toLowerCase() === localStorage.getItem("lang").toLowerCase())[0]
        userStore.changeClientLanguage({
          id: selectedLang.id,
          isoCode: selectedLang.isoCode.toLowerCase(),
          text: selectedLang.name
        })
      }
    }
  }, [languagesStore.data])

  useEffect(() => {
    if (!plansStore.data && plansStore) {
      plansRefetch();
    }
  }, []);

  return (
    <>
      <Helmet
        prioritizeSeoTags
        htmlAttributes={{ lang: userStore.clientData.language.isoCode.toLowerCase() }}
        encodeSpecialCharacters={true}
        // titleTemplate="%s - WiSE Business" // TEMPLATE
        defaultTitle={t("business_SEO_home_title")} // IF NO TITLE -> default
        title={t("business_SEO_home_title")} // TITLE CURRENT ITEM
        meta={[
          {
            name: `description`,
            content: t("business_SEO_home_description"),
          },
          {
            name: `keywords`,
            content: t("business_SEO_home_keywords"),
          },
          {
            property: "og:title",
            content: t("business_SEO_home_title")
          },
          {
            property: "og:description",
            content: t("business_SEO_home_description")
          },
          {
            property: "twitter:title",
            content: t("business_SEO_home_title")
          },
          {
            property: "twitter:description",
            content: t("business_SEO_home_description")
          },
        ]}
      >
        {/* <meta name="title" content={t("business_SEO_home_title")} /> */}
        {/* LANGUAGES */}
        {/* <link rel="shortlink" href="https://www.business.wisepeople.be/en" /> */}
        {/* <link rel="alternate" hreflang="en" href="https://www.business.wisepeople.be/en" /> */}
      </Helmet>
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <Navigation
        />
        {
          userStore.clientData.acceptedCookies ? null :
            <CookiesPopup />
        }
        {

          userStore.clientData.checkedRememberMe === false ?
            <Routes>
              <Route
                path="*"
                element={<div className="d-flex justify-content-center"><Loading text={null} big fullscreen /></div>}
              />
              {/* <Route path="/" exact element={
                <HomeRedirectPage />
              } /> */}
            </Routes>
            :
            <Routes>
              <Route path="/" exact element={
                <HomeRedirectPage />
              } />

              <Route path="/logout" exact element={
                <LogoutPage />
              } />

              <Route
                path="/home"
                element={<Navigate to="/" />}
              />

              <Route path="/login" exact element={
                <LoginPage />
              } />

              <Route path="/forgot" exact element={
                <ForgotPage />
              } />

              <Route path="/register" exact element={
                <RegisterPage />
              } />

              <Route path="/wise" exact element={
                <WisePage />
              } />

              <Route path="/privacy-policy" exact element={
                <PolicyPage />
              } />

              <Route path="/terms-conditions" exact element={
                <TermsPage />
              } />

              <Route path="/dashboard" exact element={
                <NewDashboardPage />
              } />
              <Route path="/recruiter-dashboard" exact element={
                <RecruitmentDashboardPage />
              } />
              <Route path="/accelerate-dashboard" exact element={
                <AccelerateDashboardPage />
              } />
              <Route path="/improve-dashboard" exact element={
                <ImproveDashboardPage />
              } />
              <Route path="/deep-dive-dashboard" exact element={
                <DeepDiveDashboardPage />
              } />

              <Route path="/agency-dashboard" exact element={
                <DashboardAgency />
              } />
              <Route path="/candidate-insight-dashboard" exact element={
                <CandidateInsightDashboardPage />
              } />
              <Route path="/ce-dashboard" exact element={
                <CeDashboardPage />
              } />

              <Route path="/must-have-dashboard" exact element={
                <MustHaveDashboardPage />
              } />

              <Route path="/create-visual" exact element={
                <CreateVisualPage />
              } />

              <Route path="/verification" exact element={
                <VerificationPage />
              } />

              <Route path="/accept" exact element={
                <AcceptPage />
              } />

              <Route path="/invite" exact element={
                <InvitePage />
              } />

              <Route path="/forgotreset" exact element={
                <RecoverAccountPage />
              } />

              <Route path="/jobs" exact element={
                <JobsPage />
              } />

              <Route path="/recruiter-branding/overview" exact element={
                <RecruiterBrandingRecommendationsPage />
              } />

              <Route path="/recruiter-branding/info" exact element={
                <RecruiterBrandingInfoPage />
              } />

              <Route path="/recruiter-branding" exact element={
                <Navigate to="/recruiter-branding/info" />
              } />

              <Route path="/company" exact element={
                <Navigate to="/company/info" />
              } />

              <Route path="/company/info" exact element={
                <CompanyInfoPage />
              } />

              <Route path="/company/share" exact element={
                <CompanySharePage />
              } />

              <Route path="/company/procedure" exact element={
                <CompanyProcedurePage />
              } />

              <Route path="/company/promise" exact element={
                <CompanyPromisePage />
              } />

              <Route path="/company/recruitment" exact element={
                <CompanyRecruitmentPage />
              } />

              <Route path="/company/recruitment-history" exact element={
                <CompanyRecruitmentHistoryPage />
              } />

              <Route path="/company/team" exact element={
                <CompanyTeamPage />
              } />

              {/* FEATURE-JOBFAIRS */}
              {/* DISABLE JOBFAIRS */}
              {/* <Route path="/jobfairs" exact element={
                <JobfairsOverviewPage />
              } />

              <Route path="/jobfairs/*" exact element={
                <JobfairsTemplatePage />
              } /> */}

              <Route path="/company/plans" exact element={
                <CompanyPlansPage />
              } />

              <Route path="/company/plans/upgrade" exact element={
                <CompanyUpgradePage />
              } />

              <Route path="/account" exact element={
                <Navigate to="/account/general" />
              } />

              <Route path="/account/alerts" exact element={
                <AccountAlertsPage />
              } />

              <Route path="/account/general" exact element={
                userStore.companyData === null || userStore.companyData === undefined ? <GuestRedirectLayout /> :
                  userStore.companyData.subscriptionData.int1 === 10 && userStore.companyData.subscriptionData.guid1 === "0e63167e-848d-42aa-9365-864318aa89d0" ?
                    <AccountGeneralRecruiterPage /> :
                    <AccountGeneralPage />
              } />

              <Route path="/account/security" exact element={
                <AccountSecurityPage />
              } />

              <Route path="/template" exact element={
                <Template />
              } />

              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        }

        <Footer t={t} />
      </BrowserRouter>
    </>
  );
}

export default App;


const useRememberMe = (props) => {
  const userStore = useUserStore();

  return useQuery({
    queryKey: ["accountRememberMe"],
    queryFn: () => axios.post(`login2`, {
      Token: props.token
    }),
    refetchOnWindowFocus: false,
    enabled: false,
    retry: 2,
    cacheTime: 0,
    onSuccess: (res) => handleRequestSuccess({
      response: res,
      callback: () => {
        const data = res.data.instance;
        handleUserLoginData({ data, userStore: userStore });
        userStore.changeClientDataItem("checkedRememberMe", true)
      },
      customCallback: () => {
        switch (parseInt(res.data.status)) {
          case 290:
            setTimeout(() => {
              userStore.changeClientDataItem("checkedRememberMe", true)
            }, 50);
            break;
          default:
            setTimeout(() => {
              userStore.changeClientDataItem("checkedRememberMe", true)
            }, 50);
            break;
        }
      }
    }),
    onError: (res) => handleRequestError({
      response: res,
      customCallback: () => {
        localStorage.removeItem("cToken")
        userStore.changeClientDataItem("checkedRememberMe", true)
      },
    })
  })
}